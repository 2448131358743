'use client';

import React, { useEffect, useRef, useState } from 'react';

import scroll from '@/assets/styles/scroll.module.scss';

import solutionGpsIcon from '@/assets/icons/home/gps.svg?url';
import solutionMapIcon from '@/assets/icons/home/map.svg?url';
import solutionScheduleIcon from '@/assets/icons/home/schedule.svg?url';
import solutionSearchIcon from '@/assets/icons/home/search.svg?url';
import solutionGpsBanner from '@/assets/images/home/manager-image.png';
import solutionMapBanner from '@/assets/images/home/owner-image.png';
import solutionScheduleBanner from '@/assets/images/home/supervisor-image.png';
import solutionSearchBanner from '@/assets/images/home/estimator-image.png';
import solution from '@/data/solution.json';
import Image from 'next/image';

const roles = [
  {
    id: 1,
    roleName: 'homeTailorMadeOwner',
  },
  {
    id: 2,
    roleName: 'homeTailorMadeManager',
  },
  {
    id: 3,
    roleName: 'homeTailorMadeSupervisor',
  },
  {
    id: 4,
    roleName: 'homeTailorMadeEstimator',
  },
];

const { solutionConstructionList } = solution;

const iconMapping = {
  solutionMapIcon,
  solutionGpsIcon,
  solutionScheduleIcon,
  solutionSearchIcon,
  solutionMapBanner,
  solutionGpsBanner,
  solutionScheduleBanner,
  solutionSearchBanner,
};

const solutionConstructionListHandle = solutionConstructionList.map((item) => ({
  id: item.id,
  user: item.user,
  title: item.title,
  description: item.description,
  icon: iconMapping[item.icon],
  banner: iconMapping[item.banner],
}));

const RoleBasedSolution = ({ t }) => {
  const [activeIndex, setActiveIndex] = useState<number>(1);
  const [isUserClick, setIsUserClick] = useState(false);

  const handleUserClick = (id: number) => {
    setActiveIndex(id);
    setIsUserClick(true);
  };

  useEffect(() => {
    if (isUserClick) return;
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) =>
        prevIndex === roles.length ? 1 : prevIndex + 1,
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [isUserClick]);

  return (
    <div className="p-5">
      <ul
        className={`${scroll.overflow_auto} flex w-full snap-x flex-nowrap gap-2 overflow-x-auto scroll-smooth px-6 py-2 md:justify-center`}
      >
        {roles.map(({ id, roleName }) => (
          <li
            key={id}
            onClick={() => handleUserClick(id)}
            className={`${activeIndex === id ? 'bg-primary-900 text-white' : ''} typo-label-sm cursor-pointer text-nowrap rounded-[100px] px-8 py-[10px] transition-all duration-500 ease-in-out md:typo-paragraph-md lg:py-[14px]`}
          >
            {t[roleName]}
          </li>
        ))}
      </ul>
      <ul>
        {solutionConstructionListHandle.map((data) => (
          <li
            className={`${activeIndex === data.id ? ' flex' : 'hidden'} w-full flex-col transition-all  duration-500 ease-in-out  md:items-center`}
            key={data.id}
          >
            <div className=" w-full max-w-[760px]">
              <Image
                src={data.banner}
                alt={`Image depicting ${t[data.user]} using GoBuid software`}
                className="w-full"
                sizes="(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 1280px"
              />
            </div>
            <div className="flex w-full max-w-[760px] gap-4 px-4 py-6 md:items-center">
              <div className="h-6 w-6 md:h-12 md:w-12">
                <Image
                  src={data.icon}
                  alt={`${t[data.title]} icon`}
                  className="w-full"
                />
              </div>
              <div className="flex flex-1 flex-col gap-[2px]">
                <h6 className=" typo-label-xs uppercase lg:typo-label-lg">
                  {t[data.title]}
                </h6>
                <p className=" typo-paragraph-sm lg:typo-paragraph-lg">
                  {t[data.description]}
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RoleBasedSolution;
