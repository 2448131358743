'use client';

import Image from 'next/image';
import ratio from '@/assets/styles/ratio.module.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function FeaturesDeskDisplay({ solutionConstructionListHandle, t }) {
  return (
    <div className=" hidden md:block">
      <ul className="grid w-full grid-cols-3 gap-6 ">
        {solutionConstructionListHandle.map((list) => (
          <li
            key={list.id}
            className={`${ratio.featuresCard} border-b-none flex w-full flex-col justify-between rounded-2xl border border-gray-300 shadow-[0_4px_15px_0_#0008211a]`}
          >
            <div
              className={`text-[18px] font-medium leading-[24px] lg:text-[24px] lg:font-semibold lg:leading-[32px] flex w-full  items-center justify-center border-b border-gray-300 py-6 `}
            >
              {t[list.title]}
            </div>
            <div className="flex  flex-1 flex-col">
              <div className="flex w-full items-center justify-center py-6">
                <Image src={list.icon} alt={`${t[list.title]} image`} className="w-[30%]" />
              </div>
              <div
                className={`text-[16px] font-normal leading-[24px] lg:text-[20px] lg:leading-[28px] w-full flex-1 pb-6 text-gray-800`}
              >
                <p className="text-center text-base">{t[list.description]}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FeaturesDeskDisplay;
