'use client';

import { useState, memo } from 'react';
import QuestionsList from '@/components/homePage/QuestionsList';

function QASection({ QAList, title, t }) {
  const [onAnswerOpenId, setOnAnswerOpenId] = useState(null);

  const handleAnswerOpen = (id) => {
    if (onAnswerOpenId === id) {
      setOnAnswerOpenId(null);
    } else {
      setOnAnswerOpenId(id);
    }
  };

  return (
    <section className=" flex flex-col items-center justify-center gap-6 px-5 py-6 md:px-10 lg:gap-8 lg:py-14">
      <div className=" flex items-center justify-center">
        <h2
          className={`w-fit px-2 py-4 text-center text-2xl text-[24px] font-semibold leading-[32px] lg:text-[48px] lg:font-bold lg:leading-[56px] `}
        >
          {t[title]}
        </h2>
      </div>
      <div className="w-full xl:w-[800px]">
        {QAList.map((data) => (
          <QuestionsList
            key={data.id}
            id={data.id}
            description={data.description}
            answer={data.answer}
            answer2={data.answer2}
            answer3={data.answer3}
            answer4={data.answer4}
            list={data.list}
            list2={data.list2}
            list3={data.list3}
            link={data.link}
            link2={data.link2}
            onAnswerOpenId={onAnswerOpenId}
            handleAnswerOpen={handleAnswerOpen}
            t={t}
          />
        ))}
      </div>
    </section>
  );
}

export default memo(QASection);
