'use client';

import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

const AnimationText = ({ words, t }) => {
  const [index, setIndex] = useState(0);

  const translatedWord = t[words[index].toLowerCase()] || words[index]
  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 1200);

    return () => {
      clearInterval(timer);
    };
  }, [words.length]);
  return (
    <AnimatePresence>
      <motion.div
        key={index}
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 0 }}
        transition={{
          duration: 0.6,
          ease: 'easeInOut',
          type: 'spring',
          bounce: 0.6,
        }}
        className={`text-5xl font-bold absolute top-14 h-[56px] lg:top-24 lg:h-[102px] lg:text-[88px] lg:leading-[96px] bg-gradient-to-r from-primary-900 from-5% via-secondary-500 via-90% to-secondary-500 to-5%  bg-clip-text text-gradient `}
      >
        {translatedWord}
      </motion.div>
    </AnimatePresence>
  );
};

export default AnimationText;
