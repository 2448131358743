'use client';

import gaEvent from '@/utils/ga';
import { useRouter } from 'next/navigation';

export default function ButtonStartFree({ t }) {
  const router = useRouter();
  const handleGaEvent = () => {
    router.push('/signup');
    gaEvent(`start_for_free_click`, {
      event_category: 'Click',
      value: 0,
    });
  };

  return (
    <button
      type="button"
      onClick={handleGaEvent}
      className="min-w-[300px] p-5 rounded-full bg-btn-main-gradient-to-r text-white transition-all duration-200  ease-out hover:bg-btn-main-gradient-to-r-hover hover:text-black lg:px-10 lg:py-5 w-fit"
    >
      <p className=' typo-heading-sm lg:typo-heading-md'>{t.homeStartButton}</p>
      <p className=' typo-paragraph-xs lg:typo-paragraph-sm'>{t.homeButtonSlogan}</p>
    </button>
  );
}
