'use client';

import dynamic from 'next/dynamic';
import React, { useState } from 'react';
const VideoPop = dynamic(() => import('@/components/popup/VideoPop'));

const VideoPopUp = ({ t }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClick = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <button
        className=" typo-label-sm"
        type="button"
        onClick={handleModalClick}
      >
        {t.homeVideoLink}
      </button>
      {isModalOpen && <VideoPop handleModalClose={handleModalClick} t={t} />}
    </>
  );
};

export default VideoPopUp;
