'use client';

import React, { useState } from 'react';
import Image from 'next/image';
import ratio from '@/assets/styles/ratio.module.scss';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function FeaturesMobCarousel({ solutionConstructionListHandle, t }) {
  const [selectedItem, setSelectedItem] = useState(1);

  return (
    <div className="md:hidden">
      <Carousel
        showThumbs={false}
        showStatus={false}
        swipeable
        dynamicHeight
        emulateTouch
        showIndicators={false}
        centerMode
        infiniteLoop
        selectedItem={selectedItem}
        onChange={setSelectedItem}
      >
        {solutionConstructionListHandle.map((list, index) => (
          <div
            key={list.id}
            className={`${ratio.featuresCard} ${index === 1 ? ratio.cardRatio1 : ratio.cardRatio07
              } border-b-none flex flex-col rounded-2xl border border-gray-300 bg-white ${index === selectedItem ? 'z-10 scale-100' : 'z-0 scale-90'
              } `}
          >
            <div
              className={`text-[18px] font-medium leading-[24px] lg:text-[24px] lg:font-semibold lg:leading-[32px] flex h-[23%] w-full items-center justify-center border-b border-gray-300 `}
            >
              {t[list.title]}
            </div>
            <div className="flex h-[58%] items-center justify-center  ">
              <Image src={list.icon} alt={`${list.title} image`} className="scale-[37%]" />
            </div>
            <div
              className={`text-[16px] font-normal leading-[24px] lg:text-[20px] lg:leading-[28px] flex h-[19%] w-full justify-center text-gray-800`}
            >
              {t[list.description]}
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default FeaturesMobCarousel;
