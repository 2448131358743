import Image from 'next/image';
import ArrowDown from '@/assets/icons/features/arrow-down.svg?url';
import Link from 'next/link';
import { BaseIcon } from '../base/BaseIcon/BaseIcon';

function QuestionsList({
  id,
  description,
  answer,
  answer2,
  answer3,
  answer4,
  list,
  list2,
  list3,
  link,
  link2,
  handleAnswerOpen,
  onAnswerOpenId,
  t
}) {
  return (
    <article
      className={`${onAnswerOpenId === id ? '' : 'md:hover:bg-gray-100'
        } flex w-full flex-col gap-4 border-b border-gray-500 px-2 py-4 text-gray-900 lg:gap-6 lg:px-4`}
    >
      <header
        className="cursor-pointer flex justify-between items-start"
        onClick={() => handleAnswerOpen(id)}
      >
        <h3 className=" typo-label-xl lg:typo-heading-sm text-start">
          {t[description]}
        </h3>
        <div className={`${onAnswerOpenId === id ? ' rotate-180' : ''} w-6 flex transition-all`}>
          <BaseIcon name="ArrowDownSimple" size={24} color="#262D42" />
        </div>
      </header>
      <div
        className={`${onAnswerOpenId === id ? '' : 'hidden'
          } typo-paragraph-sm lg:typo-paragraph-lg text-gray-800`}
      >
        <p>
          {t[answer]}
          {link && (
            <span>
              <Link href={link} className="cursor-pointer text-primary-900 inline-block">
                {t.homeHere}.
              </Link>
            </span>
          )}
        </p>

        <div className=" flex flex-col gap-2">
          {answer2 && (
            <p>
              {t[answer2]}
              <span>
                {link2 && (
                  <Link
                    href={link2}
                    alt="youtube link"
                    className="cursor-pointer text-primary-900"
                  >
                    {t.homeHere}.
                  </Link>
                )}
              </span>
            </p>
          )}
          <div className="flex flex-col gap-1">
            {list && <ul>{t[list]}</ul>}
            {list2 && <ul>{t[list2]}</ul>}
            {list3 && <ul>{t[list3]}</ul>}
          </div>
          {answer3 && <p>{t[answer3]}</p>}
          {answer4 && <p>{t[answer4]}</p>}
        </div>
      </div>
    </article>
  );
}

export default QuestionsList;
